import React from 'react'
import PropTypes from 'prop-types'
import { FaTwitter, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"


const Share = ({socialConfig, tags}) => (
    <div className="inline-block mt-4 text-xs md:text-sm lg:text-base">
        <div className="sm:flex sm:align-middle">
        <FacebookShareButton url={socialConfig.config.url} className="px-3 py-2 bg-blue-200 rounded-full hover:bg-green-300">
            <FaFacebookF className="inline-block h-6 w-6 text-blue-600 mr-2" />Share on Facebook
        </FacebookShareButton>
        <TwitterShareButton url={socialConfig.config.url} className="mt-4 sm:mt-0 sm:ml-4 px-3 py-2 bg-blue-200 rounded-full hover:bg-green-300" title={socialConfig.config.title} via={socialConfig.twitterHandle}>
            <FaTwitter className="inline-block h-6 w-6 text-blue-600 mr-2" />Share on Twitter
        </TwitterShareButton>
        <LinkedinShareButton url={socialConfig.config.url} className="mt-4 sm:mt-0 sm:ml-4 px-3 py-2 bg-blue-200 rounded-full hover:bg-green-300">
            <FaLinkedinIn className="inline-block h-6 w-6 text-blue-600 mr-2" />Share on LinkedIn
        </LinkedinShareButton>
        </div>
    </div>
)

Share.propTypes = {
    socialConfig: PropTypes.shape({
        twitterHandle: PropTypes.string.isRequired,
        config: PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
        }),
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
}

Share.defaultProps = {
    tags: [],
}

export default Share;