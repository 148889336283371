import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Share from '../components/share'

import { FaRegUser, FaRegClock, FaArrowLeft, FaTag } from 'react-icons/fa'

export default function BlogPostTemplate({data}) {

    const {
        post: {
            html,
            frontmatter: {
                path,
                title,
                author,
                date,
                tags,
                featured_image
            }
        },
        site: {
			siteMetadata: { url, twitterHandle },
		},
    } = data

    console.log(data.site)
    
    return (
        <Layout>
            <section>
                <h1 className="text-center text-xl sm:text-2xl lg:text-4xl font-semibold bg-gray-200">{title}</h1>
                <p className="xs:ml-1 xs:mb-4 xs:mt-2 text-xs xs:text-sm text-center">
                    <FaRegUser className="inline-block mr-2 h-4 w-4 text-gray-400" />
                    {author}
                    <FaRegClock className="inline-block ml-6 mr-2 h-4 w-4 text-gray-400" />
                    {date}
                    <FaTag className="inline-block ml-6 mr-2 h-4 w-4 text-gray-400" />
                    {tags.map(element => (
                        <Link to={`/articles/tags/${element}`} key={`${element}`} className="mr-2 hover:font-bold hover:text-green-500">{element}</Link>
                    ))}
                </p>
                <article className="px-4 xs:px-10 mt-4 sm:mt-8 lg:mt-12 mb-4 sm:mb-8 lg:mb-12 max-w-4xl mx-auto">
                    <Img fluid={featured_image.src.childImageSharp.fluid} alt={featured_image.alt} 
                        className="hidden xs:block h-64 max-w-xl md:mx-auto md:w-2/3 lg:px-0"></Img>
                    <div dangerouslySetInnerHTML={{__html: html}} className="blogPost mt-4 sm:mt-8 lg:mt-12"/>
                    <div className="mt-8 text-sm text-center sm:text-base sm:text-left">
                        <span className="hidden xs:inline">Tags</span>
                        {tags.map(element => (
                            <Link to={`/articles/tags/${element}`} key={`${element}`} className="ml-3 px-3 py-1 rounded-full bg-gray-300 hover:bg-green-300">{element}</Link>
                        ))}
                    </div>
                    <div className="text-center pt-6">
                        <Share socialConfig={{
                            twitterHandle,
                            config: {
                                url: `${url}${path}`,
                                title,
                            },
                        }}
                        tags={tags}
                    />
                    </div>

                    <Link to="/blog" className="block flex items-center justify-center sm:justify-start mt-8 sm:ml-8 hover:text-green-600 ">
                        <FaArrowLeft className="inline-block h-4 w-4 mr-2"/>Back to Article list
                    </Link>
                </article>
            </section>
        </Layout>
    )
}

export const postQuery = graphql`
query BlogPostByPath($path: String!) {
    site {
        siteMetadata {
            url
            twitterHandle
        }
    }
    post: markdownRemark(frontmatter: {path: { eq: $path}}) {
            html
            frontmatter {
                path
                title
                author
                date
                tags
                featured_image {
                    alt
                    title
                    src {
                        childImageSharp {
                            fluid(maxWidth: 1024) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }

`
